import React, { useEffect, useState } from 'react'
import logo from './logo.svg'
import './App.css'
import PokerTable from './poker/ui/PokerTable'
import { ApiKeyInput } from './ApiKeyInput'

function App() {
    const [apiKey, setApiKey] = useState('')

    return (
        <div className="App">
            <ApiKeyInput apiKey={apiKey} setApiKey={setApiKey} />
            <PokerTable apiKey={apiKey} />
            <div className={'footer'}>Created By Ellek Linton</div>
        </div>
    )
}

export default App
