import React, { useState } from 'react'
import { ActionButton } from './poker/ui/actions/ActionButton'

export function ApiKeyInput({
    apiKey,
    setApiKey,
}: {
    apiKey: string
    setApiKey: (apiKey: string) => void
}) {
    return (
        <div>
            <input
                type="text"
                value={apiKey}
                onChange={(e) => setApiKey(e.target.value)}
                placeholder="API Key"
            />
            <button
                style={{
                    backgroundColor: 'var(--color-dark-grey)',
                    color: 'white',
                    border: '1px solid white-',
                }}
                onClick={() => {
                    localStorage.setItem('apiKey', apiKey)
                }}
            >
                Test API Key
            </button>
        </div>
    )
}
