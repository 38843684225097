import { performShuffle } from './deck'
import personas from './personas.json'

export type TPersona = {
    name: string
    description: string
}

/* Persona Generation Prompt
Use GPT 4 for this

I am creating a poker website, which uses Chat GPT to create other "agents" / players in the game. I need to generate a list of 20 possible poker "personas", which will be fed as context to GPT before asking to provide the player's decision. It should be highly descriptive. The players should generally be very good players, and the distribution of player personas should match what you might find at a professional poker tournament. Return the personas in JSON format as an array of objects. Each object should have "name" and "persona" attributes which are both strings. Create one specific player named "Jerry" who is an extremely professional poker player, and highly values position at the table over the cards he has been dealt. I.e. if He is dealt a low hand, but is on the dealer position, he might even raise just for position. His favorite hand is 4 6, so he always plays that. Also create players named "Slim", "Chris", "Greg", "Sam", and "Zoey". For the rest, you can choose appropriate names. Generate at least 5 sentences for each, describing their play style in detail.


 */

export const PERSONAS = personas.personas as TPersona[]

export function getRandomPersonas(n = 9) {
    return performShuffle(PERSONAS.slice()).slice(0, n)
}
