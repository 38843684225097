import OpenAI from 'openai'

import { BaseDecisionMaker } from './decision-maker'
import { TCard, TGameState, TPlayerActionType, TPlayerEvent } from '../../types'
import { getCurrentBettingState } from '../../ui/PokerTable'
import { RandomDecisionMaker } from './random-decision-maker'
import { TPlayerPosition } from '../../ui/player/Player'

type ChatGPTResponse = {
    explanation: string
    decision: TPlayerActionType
    decision_time: number
    amount?: number
}

export class ChatGPTDecisionMaker extends BaseDecisionMaker {
    private static mapCardToShorthand(card: TCard): string {
        return `${card.rank}${card.suit[0].toUpperCase()}`
    }

    private static getShorthandPlayerName(playerIndex: number): string {
        return `p${playerIndex}`
    }

    private static getPlayerPersona(
        gameState: TGameState,
        playerIndex: number
    ): string {
        const player = gameState.players[playerIndex]

        return player.persona.replaceAll(
            player.name,
            this.getShorthandPlayerName(playerIndex)
        )
    }

    private static eventToText(
        event: TPlayerEvent & { playerIndex?: number }
    ): string {
        const amount = event.type === 'check' ? undefined : event.amount

        const player =
            event.playerIndex !== undefined && event.playerIndex !== -1
                ? `${this.getShorthandPlayerName(event.playerIndex)}:`
                : ''
        const type = event.isIncompleteRaise ? 'incomplete_raise' : event.type
        const amountStr = amount ? `[$${amount}]` : ''
        const cards_dealt = event.cards_dealt
            ? `[${event.cards_dealt.map((c) => this.mapCardToShorthand(c)).join('/')}]`
            : ''

        return `${player}${type}${amountStr}${cards_dealt}`
    }

    private static getAvailableActions(
        gameState: TGameState,
        playerIndex: number
    ): string {
        const maxBet = gameState.players[playerIndex].stackSize
        return this.availableActions(gameState, playerIndex)
            .map((action) => {
                if (action.type === 'bet' || action.type === 'raise') {
                    return `${action.type}[min$${action.amount},max$${maxBet}]`
                }
                return this.eventToText(action)
            })
            .join(',')
    }

    private static getFallbackAmountForAction(
        actionName: TPlayerActionType,
        gameState: TGameState,
        playerIndex: number
    ): number | undefined {
        return this.availableActions(gameState, playerIndex).filter(
            (action) => action.type === actionName
        )[0]?.amount
    }

    private static getPastActions(gameState: TGameState): string {
        return gameState.round_history
            .map((event) => this.eventToText(event))
            .join(',')
    }

    private static getOtherPlayerChipStacks(gameState: TGameState): string {
        return gameState.players
            .map((p, i) => `${this.getShorthandPlayerName(i)}:$${p.stackSize}`)
            .join(',')
    }

    private static getOtherPlayerStates(gameState: TGameState): string {
        return gameState.players
            .map((p, i) => `${this.getShorthandPlayerName(i)}:${p.state}`)
            .join(',')
    }

    private static playerPosition(
        gameState: TGameState,
        playerIndex: number
    ): string {
        let position: TPlayerPosition
        if (playerIndex === gameState.dealer_position) {
            position = 'dealer'
        } else if (playerIndex === gameState.small_blind_position) {
            position = 'small_blind'
        } else if (playerIndex === gameState.big_blind_position) {
            position = 'big_blind'
        }

        if (position) {
            return `Your current position is ${position}.`
        }

        return `The current dealer is ${this.getShorthandPlayerName(gameState.dealer_position)}, 
small blind is ${this.getShorthandPlayerName(gameState.small_blind_position)},
and big blind is ${this.getShorthandPlayerName(gameState.big_blind_position)}.`
    }

    private static buildPrompt(gameState: TGameState, playerIndex: number) {
        const player = gameState.players[playerIndex]
        const available_action_names = this.availableActions(
            gameState,
            playerIndex
        ).map((a) => a.type)

        if (available_action_names.length === 0) {
            throw new Error('No available actions! in ChatGPT Decision Maker.')
        }

        const systemPrompt =
            `You are playing in a no-limit Texas Hold'em poker tournament.

The small blind is ${Math.round(gameState.big_blind / 2)} and the big blind is ${gameState.big_blind}.

You are ${this.getShorthandPlayerName(playerIndex)}. ${this.getPlayerPersona(gameState, playerIndex)} 

The previous sequence of events is [${this.getPastActions(gameState)}]. ${this.playerPosition(gameState, playerIndex)}

The current chip stacks of all players are [${this.getOtherPlayerChipStacks(gameState)}].

The current states of all players are [${this.getOtherPlayerStates(gameState)}].

Your cards are [${player.cards.map((c) => this.mapCardToShorthand(c))}].

And the table cards are [${gameState.communityCards.map((c) => this.mapCardToShorthand(c))}].`
                .replaceAll('\n', ' ')
                .replaceAll('  ', ' ')

        const userPrompt =
            `It is your turn to act. You have ${player.stackSize} chips left.

Your available actions are [${this.getAvailableActions(gameState, playerIndex)}].

Looking at the previous history of the game, your playing style, and the chip stacks, what action do you want to take?

Remember, your goal is to stay in the tournament as long as possible, so don't be overly aggressive with raises.

Respond as a JSON object with the fields: 
'explanation': string, a 3 sentence justification of your decision;
'decision': '${available_action_names.join("'|'")}';
'decision_time': number, amount of time, between 100 and 5000, in ms, that the player took to make this decision. If it is a hard decision, this will be higher. If easy decision, will be shorter.; 
'amount': optional number if applicable.`
                .replaceAll('\n', ' ')
                .replaceAll('  ', ' ')

        return { systemPrompt, userPrompt }
    }
    static async makeDecision(
        gameState: TGameState,
        playerIndex: number,
        apiKey?: string
    ): Promise<TPlayerEvent | null> {
        if (!apiKey) {
            // return NO decision because we need API key and this will be triggered when API key changes anyway
            return null
            // return RandomDecisionMaker.makeDecision(gameState, playerIndex)
        }

        const openai = new OpenAI({
            apiKey,
            dangerouslyAllowBrowser: true,
        })

        const chatGptPrompt = this.buildPrompt(gameState, playerIndex)
        console.log(
            `[ChatGPT][Prompt][${gameState.players[playerIndex].name}][System Prompt]:`
        )
        console.log(chatGptPrompt.systemPrompt)
        console.log(
            `[ChatGPT][Prompt][${gameState.players[playerIndex].name}][User Prompt]:`
        )
        console.log(chatGptPrompt.userPrompt)
        console.log()
        console.log('[ChatGPT][Sending Completion Request]')

        const completion = await openai.chat.completions.create({
            messages: [
                {
                    role: 'system',
                    content: chatGptPrompt.systemPrompt,
                },
                {
                    role: 'user',
                    content: chatGptPrompt.userPrompt,
                },
            ],
            model: 'gpt-3.5-turbo-1106',
            response_format: {
                type: 'json_object',
            },
        })

        const result = JSON.parse(
            completion.choices[0].message.content!
        ) as ChatGPTResponse
        console.log('[ChatGPT][Received Completion Response]', result)

        return {
            type: result.decision,
            amount:
                result.amount ??
                this.getFallbackAmountForAction(
                    result.decision,
                    gameState,
                    playerIndex
                ),
            // TODO: isIncompleteRaise
        }
    }
}
